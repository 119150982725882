import React from "react";
import { Icon } from "components/common/Icons/Icon";
import "./TextInput.scss";

/*interface inputPropsInterface {
  onChange: (val: any) => void;
  className?: string;
  error?: boolean;
  errorLabel?: string;
  iconName?: string;
  inputData: {[key: string]: any}
}
*/
export const TextInput = (props) => {
  const {
    className,
    errorLabel,
    iconName,
    error,
    onChange,
    onFocusOut,
    rawValue,
    defaultValue,
    code,
    inputData
  } = props;

  return (
    <label className={`TextInput-labelCover ${error ? "error" : ""} ${className || ""}`} >
      {
        iconName && (
          <span className="TextInput-icon">
            <Icon name={iconName} />
          </span>
        )
      }
      <input
        type="text"
        {...inputData}
        data-key={code}
        data-raw={rawValue || ""}
        data-default={defaultValue || ""}  
        className={`TextInput${iconName ? " withIcon":""}`}
        onChange={ ({target: {value}}) => onChange && onChange(value)}
        onBlur={onFocusOut || null}
        autoComplete="off"
      />
      {error && <span className="TextInput-errorLabel">{errorLabel}</span>}
    </label>
  );
};



export const TextArea = (props) => {
  const {
    rawValue,
    defaultValue,
    error,
    onChange,
    customClass,
    code,
    rows,
    inputData
  } = props;

  return (
    <label
      className={`Input ${
        customClass ? customClass : ""
      }`}
    >
      <div className="Input-wrapInput">
        <textarea
          {...inputData}
          rows={rows || 3}
          className={`Input-formInput ${error ? "error" : ""}`}
          data-key={code}
          data-raw={rawValue || ""}
          data-default={defaultValue || ""}
          onChange={(e) =>
            onChange && onChange(e)
          }
        />
      </div>
    </label>
  );
};

export const PickemTextInput = (props) => {
  const {
    className,
    errorLabel,
    iconName,
    error,
    onChange,
    onFocusOut,
    rawValue,
    defaultValue,
    code,
    inputData
  } = props;

  return (
    <label className={`TextInput-labelCover ${error ? "error" : ""} ${className || ""}`} >
      {
        iconName && (
          <span className="TextInput-icon">
            <Icon name={iconName} />
          </span>
        )
      }
      <input
        type="text"
        {...inputData}
        data-key={code}
        data-raw={rawValue || ""}
        data-default={defaultValue || ""}  
        className={`TextInput${iconName ? " withIcon":""}`}
        onChange={ (e) => onChange && onChange(e)}
        onBlur={onFocusOut || null}
        autoComplete="off"
      />
      {error && <span className="TextInput-errorLabel">{errorLabel}</span>}
    </label>
  );
};
