import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { Link } from "react-router-dom"
import { PickemTable } from "components/common/Table/PickemTable";
import { headList } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
//import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemGroupPicksPage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

//TODO: turn on post season for current week possibility

const PickemGroupPicksPage = ({
    history,
    location,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [groupPickData, setGroupPickData] = useState({});
    const [groupPicksDisplayData, setGroupPicksDisplayData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingGroupPickData, setIsLoadingGroupPickData] = useState(true)
    const [currentWeek, setCurrentWeek] = useState(null);
    const [eventData, setEventData] = useState({});
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)

    useEffect(() => {
        updateMeta({
            title: "Picks",
        });
    }, []);

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }
        
        setNameString(name)
        if (competitionData.competitionDescription) {
            setCompetitionDescription(competitionData.competitionDescription)
        }
        if (competitionData.competitionName) {
            setCompetitionName(competitionData.competitionName)
        }
        
    }, [competitionData])

    useEffect(() => {
        getGroupData(currentWeek)
    }, [])

    useEffect(() => {   
        console.log("Group Pick Data Set:", groupPickData)
        analyzeGroupData()
    }, [groupPickData])

    useEffect(() => {
        console.log("Analyze Data:", eventData)
        analyzeEventData()
    }, [eventData])

    useEffect(() => {
        setIsLoadingGroupPickData(false)
    }, [groupPicksDisplayData])

    const getGroupData = (weekNumber) => {
        functions.httpsCallable('pickemGroupPicksPage')({
            body: JSON.stringify({
                competitionId: competitionId,
                currentWeek: weekNumber
            })
        }).then((result) => {
            setIsLoading(false)
            console.log("Pickset Result:", result.data.data.eventData)
            if (result.data.success) {
                setIsLoading(false)
                setGroupPickData({...result.data.data.eventData})
                //setInitialEventData(() => ({...result.data.data.eventData}))
                if (!weekNumber) {
                    setCurrentWeek(result.data.data.currentWeek)
                }
            }
        })
    }

    const analyzeGroupData = () => {
        console.log("Analyzing User Pickset", groupPickData)
        const eventRows = {
            "Favored": [],
            "Scores": [],
            "Spread": [],
            "Underdog": [],
            "Status": [],
            "EventIds": [],
            "Winners": []
        }
        if (groupPickData.competitorData) {
            const competitorKey = Object.keys(groupPickData.competitorData)[0]
            for (const event in groupPickData.competitorData[competitorKey].events) {
                const data = groupPickData.competitorData[competitorKey].events[event]
                eventRows["Favored"].push(data.favoriteShort)
                const scoreString = data.favoriteScore && data.underdogScore ? `${data.favoriteScore} - ${data.underdogScore}` : "-"
                eventRows["Scores"].push(scoreString)
                eventRows["Spread"].push(data.pointSpreadFavorite)
                eventRows["Underdog"].push(data.underdogShort)
                eventRows["Status"].push(data.status)
                eventRows["EventIds"].push(data.eventId)
                eventRows["Winners"].push(data.winner)
            }
    
            setEventData(eventRows)
        }
        
    }

    const analyzeEventData = () => {
        let groupPicksByUser = []
        
        if (groupPickData.competitorData) {
            for (const competitorId in groupPickData.competitorData) {
                const competitor = groupPickData.competitorData[competitorId]
                const competitorPickData = []
                for (const id of eventData.EventIds) {
                    competitorPickData.push(
                        {
                            pick: competitor.events[id].userPickShort,
                            point: competitor.events[id].point,
                            status: competitor.events[id].status
                        })
                }

                const competitorObj = {
                    id: competitor.id,
                    isRandomUser: competitor.isRandomUser,
                    name: competitor.name,
                    picksetName: competitor.picksetName,
                    points: competitor.points,
                    orderedPicks: competitorPickData
                }
                groupPicksByUser.push(competitorObj)
            }
            console.log("Group Picks By User:", groupPicksByUser)
            setGroupPicksDisplayData(() => groupPicksByUser.sort((a, b) => b.points - a.points))
        }
        
    }

    const weekClicked = (e) => {
        getGroupData(e.target.value)
    }

    const createWeeks = (numWeeks, isPost) => {
        const weekList = []
        const selected = {}
        for (let weekNumber = 1; weekNumber <= numWeeks; weekNumber++) {
            const isCurrentWeek = !isPost && currentWeek === weekNumber ? true : false
            selected[weekNumber] = isCurrentWeek
            //weekList.push((<div key={weekNumber} onClick={weekClicked} className={`col PickemGroupPicksPage-weeks ${isCurrentWeek ? "PickemGroupPicksPage-selected": null}`}><p>{weekNumber}</p></div>))
            weekList.push((<button key={weekNumber} value={weekNumber} onClick={e => weekClicked(e, "value")} className={`PickemGroupPicksPage-weekButton ${isCurrentWeek ? "PickemGroupPicksPage-currentWeek": "PickemGroupPicksPage-notCurrentWeek"}`}>{weekNumber}</button>))
        }
        return weekList
    }

    const createCompetitorData = () => {
        console.log("Create Competitor:", groupPicksDisplayData.length)
        if (groupPicksDisplayData.length > 0) {
            return (
                <table className="PickemGroupPicksPage-table">
                    {
                        <tr>
                            <td className="PickemGroupPicksPage-td-label" colSpan={(groupPicksDisplayData[0].orderedPicks.length + 1)}>
                                    <p>Team Name</p>
                            </td>
                            <td className="PickemGroupPicksPage-td">Points</td>
                        </tr>
                    }
                    {groupPicksDisplayData.map((competitor) => {
                        return (
                            <tr>
                                <td className="PickemGroupPicksPage-td-label">
                                    <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: competitor.name, picksUserId: competitor.id, isRandomUser: competitor.isRandomUser})}>
                                        {competitor.picksetName}
                                    </Link>
                                </td>
                                {competitor.orderedPicks.map((item) => {
                                    return (<td className={`${determineStatusStyle(item)}`}>{item.pick ? item.pick : "-"}</td>)
                                })}
                                <td className="PickemGroupPicksPage-td">{competitor.points}</td>
                            </tr>
                        )
                    })}
                </table>
            )
        }
    }

    const  createHeaderData= () => {
        if (eventData["Favored"]) {
            console.log(eventData)
            return (
                <table className="PickemGroupPicksPage-table">
                    <tr>
                        <td className="PickemGroupPicksPage-td-label">Favored</td>
                        {eventData["Favored"].map((f, i) => (<td className={`${eventData["Winners"][i] ? (eventData["Winners"][i] === f ? "PickemGroupPicksPage-td-win" : "PickemGroupPicksPage-td-lose") : "PickemGroupPicksPage-td"}`}>{f}</td>))}
                    </tr>
                    <tr>
                        <td className="PickemGroupPicksPage-td-label"> Spread</td>
                        {eventData["Spread"].map((f) => (<td className="PickemGroupPicksPage-td">{f}</td>))}
                    </tr>
                    <tr>
                        <td className="PickemGroupPicksPage-td-label">Underdog</td>
                        {eventData["Underdog"].map((f, i) => (<td className={`${eventData["Winners"][i] ? (eventData["Winners"][i] === f ? "PickemGroupPicksPage-td-win" : "PickemGroupPicksPage-td-lose") : "PickemGroupPicksPage-td"}`}>{f}</td>))}
                    </tr>
                    <tr>
                        <td className="PickemGroupPicksPage-td-label">Score</td>
                        {eventData["Scores"].map((f) => (<td className="PickemGroupPicksPage-td">{f}</td>))}
                    </tr>
                    <tr>
                        <td className="PickemGroupPicksPage-td-label">Status</td>
                        {eventData["Status"].map((f) => (<td className="PickemGroupPicksPage-td">{"-"}</td>))}
                    </tr>
                    
                </table>
            )
        }
    }

    const determineStatusStyle = (item) => {
        let className = "PickemGroupPicksPage-td"
        if (item.status === "final" || item.status === "f/ot") {
            if (item.point) {
                className = "PickemGroupPicksPage-td-win"
            }
            else if (item.push) {
                className = "PickemGroupPicksPage-td"
            }
            else if (item.point === 0) {
                className = "PickemGroupPicksPage-td-lose"
            }
            else {
                className = "PickemGroupPicksPage-td"
            }
        }

        return className
    }

    return (
        <PickemLayoutPage
            title="Group Picks"
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
        >
            <div className="PickemGroupPicksPage">
                <div className="PickemGroupPicksPage-weekView">
                    {!isLoading && groupPickData.regWeeks ? <div className="PickemGroupPicksPage-weeks">
                        <div className="PickemGroupPicksPage-weeks">
                            <div style={{margin: '0.25em'}}><p>Regular</p></div>
                            {
                                createWeeks(groupPickData.regWeeks, false)
                            }
                        </div>
                    </div> : null}
                    {!isLoading && groupPickData.postWeeks ? <div className="PickemGroupPicksPage-weeks">
                        <div className="PickemGroupPicksPage-weeks">
                            <div style={{margin: '0.25em'}}><p>Post</p></div>
                            {
                                createWeeks(groupPickData.postWeeks, true)
                            }
                        </div>
                    </div> : null}
                </div>
                <div>
                    {!isLoadingGroupPickData && groupPicksDisplayData.length > 0 ? createHeaderData() : <Spinner /> }
                </div>
                <div>
                    {!isLoadingGroupPickData && groupPicksDisplayData.length > 0 ? createCompetitorData() : null }
                </div>
            </div>
            
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemGroupPicksPage);
